import React, { useRef } from "react";
import { ConfigProvider, Input, Select, SelectProps, Spin } from "antd";
import { useQuery } from "react-query";
import { uniqBy, compact, isEqual } from "lodash";
import { QueryKeyType } from "types/query-keys";

interface SelectWithSearchProps extends SelectProps {
  queryFn: (options: any) => Promise<any>;
  queryKeyObject: QueryKeyType;
  dataLabelFn?: (data: any) => string;
  initialOpts?: any;
  disabled?: boolean;
  filters?: any;
}

export const SelectWithSearch = (props: SelectWithSearchProps) => {
  const {
    queryFn,
    queryKeyObject,
    dataLabelFn = undefined,
    initialOpts = [],
    disabled = false,
    filters = {},
    onChange,
    ...selectOnlyProps
  } = props;

  const queryProps = useRef({
    search: "",
    pageNumber: 1,
    dropDownData: compact(initialOpts || []),
  });
  const valueRef = useRef(props.value);
  const filterRef = useRef(filters || {});
  valueRef.current = props.value;

  const fetchDropDownData = async (search: string, page: number) => {
    if (disabled) {
      return [];
    }

    try {
      return queryFn({
        search,
        page,
        filters,
      });
    } catch (_error) {
      console.log(_error);
      return [];
    }
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: queryKeyObject.paginate(
      { filters, search: queryProps.current.search },
      queryProps.current.pageNumber,
    ),
    queryFn: () =>
      fetchDropDownData(
        queryProps.current.search,
        queryProps.current.pageNumber,
      ),
    enabled: !disabled,
  });

  if (!isLoading && data) {
    if (isEqual(filterRef.current, filters)) {
      queryProps.current.dropDownData = uniqBy(
        [...queryProps.current.dropDownData, ...data],
        "id",
      );
    } else {
      valueRef.current = null;
      queryProps.current.dropDownData = data;
      filterRef.current = filters;
    }
  }

  const handleSearch = (keyword: string) => {
    queryProps.current = {
      ...queryProps.current,
      dropDownData: [],
      search: keyword,
      pageNumber: 1,
    };
    refetch();
  };

  const handleScroll = (e: any) => {
    e.persist();
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      data.length >= 20
    ) {
      queryProps.current = {
        ...queryProps.current,
        pageNumber: queryProps.current.pageNumber + 1,
      };
      refetch();
    }
  };

  const handleChange = (value: string, option: any) => {
    valueRef.current = value;
    onChange && onChange(value, option);
  };

  const handleFocus = () => {
    queryProps.current = {
      ...queryProps.current,
      search: "",
      pageNumber: 1,
    };
    refetch();
  };

  let selectOptions = queryProps.current.dropDownData;
  if (dataLabelFn) {
    selectOptions = queryProps.current.dropDownData.map((dt: any) => ({
      value: dt.id,
      label: dataLabelFn(dt),
      ...dt,
    }));
  }

  const { componentDisabled } = ConfigProvider.useConfig();

  if (componentDisabled) {
    const initialSelectedOpt = compact(initialOpts || []);
    let selectedOpt = initialSelectedOpt.find((opt: any) => {
      if (opt.id === valueRef.current) return true;

      if (
        opt.options &&
        opt.options.find((subOpt: any) => subOpt.id === valueRef.current)
      )
        return true;

      return false;
    });

    if (selectedOpt && selectedOpt.options) {
      selectedOpt = selectedOpt.options.find(
        (opt: any) => opt.id === valueRef.current,
      );
    }
    const label =
      (dataLabelFn && dataLabelFn(selectedOpt || {})) ||
      selectedOpt?.label ||
      valueRef.current;

    return <Input value={label} />;
  }

  return (
    <Select
      showSearch
      {...selectOnlyProps}
      options={selectOptions}
      value={valueRef.current}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={handleSearch}
      disabled={componentDisabled || disabled}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      onChange={handleChange}
      onFocus={handleFocus}
      onPopupScroll={handleScroll}
    />
  );
};
