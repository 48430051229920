import {
  Card,
  Col,
  Divider,
  Form,
  Grid,
  Image,
  Input,
  Row,
  Typography,
} from "antd";
import React from "react";

import { fallbackImage } from "assets/fallbackImage";
import { useTranslation } from "react-i18next";
import { AssetRegistry } from "types/AssetRegistry";
import { formatCurrency } from "helpers/format-currency";
import { FillReceiveDate } from "./FillReceiveDate";

export const AssetRegistryShow = ({
  assetRegistry,
  title,
}: {
  assetRegistry: AssetRegistry;
  title: string;
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const registryWatch = Form.useWatch(["asset_registry"], form);
  const startDepreciationDate =
    registryWatch?.[assetRegistry.id]?.start_depreciation_date;

  const screens = Grid.useBreakpoint();
  return (
    <Row style={{ marginLeft: 20, marginBottom: 20 }}>
      <Typography.Title level={4} style={{ color: "#677292", fontWeight: 500 }}>
        {title}
      </Typography.Title>
      <Divider />
      <Col span={24}>
        <Card className="show-card">
          <Row>
            <Col sm={8} xs={0} hidden={screens.xs}>
              <Card.Meta
                avatar={
                  <Image
                    width={200}
                    preview={false}
                    src={assetRegistry.fixed_asset.image_url || fallbackImage}
                    fallback={fallbackImage}
                  />
                }
              />
            </Col>
            <Col sm={8} xs={24}>
              <Form.Item
                hidden
                name={[
                  "asset_registry",
                  `${assetRegistry.id}`,
                  "start_depreciation_date",
                ]}
                rules={[
                  {
                    required: true,
                  },
                ]}
                initialValue={assetRegistry.start_depreciation_date}
              >
                <Input type="hidden" />
              </Form.Item>
              <div style={{ marginLeft: 10 }}>
                <Card.Meta
                  title={t(
                    "activerecord.attributes.fixed_asset.asset_registry_other_info.serial_number",
                  )}
                  description={assetRegistry.serial_number || assetRegistry.sku}
                />
                <Card.Meta
                  title={t("activerecord.attributes.fixed_asset.received_date")}
                  description={
                    assetRegistry.start_depreciation_date ||
                    startDepreciationDate || (
                      <FillReceiveDate
                        assetRegistry={assetRegistry}
                        onChange={(registry: AssetRegistry) => {
                          form.setFieldValue(
                            [
                              "asset_registry",
                              `${assetRegistry.id}`,
                              "start_depreciation_date",
                            ],
                            registry.start_depreciation_date,
                          );
                        }}
                      />
                    )
                  }
                />
                <Card.Meta
                  title={t("activerecord.attributes.fixed_asset.book_value")}
                  description={formatCurrency(assetRegistry.book_value || 0)}
                />
              </div>
            </Col>
            <Col sm={8} xs={24}>
              <Card.Meta
                title={t(
                  "fixed_asset_additions.layout.accumulated_depreciation",
                )}
                description={formatCurrency(
                  assetRegistry.accumulated_depreciation || 0,
                )}
              />
              <Card.Meta
                title={t("fixed_asset_additions.layout.unbooked_depreciation")}
                description={formatCurrency(assetRegistry.unbooked_amount || 0)}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
