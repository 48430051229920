import React, { useState } from "react";
import { Button, Card, Col, Divider, Modal, Row, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { AssetRegistry } from "types/AssetRegistry";
import { CalculationDepreciationEntry } from "./CalculationDepreciationEntry";

export const CalculateDeprecation = ({ merge }: { merge: AssetRegistry }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("depreciation-entries");

  const calculateDepreciation = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const depreciated = (
    <Card className="bg-gray-light" style={{ width: "70%", float: "right" }}>
      <span>{t("fixed_asset_additions.layout.depreciation_calculated")}</span>
    </Card>
  );

  const onTabChange = (active: string) => {
    setActiveTab(active);
  };

  return (
    <>
      <Row>
        <Col
          span={24}
          style={{
            color: "#25233a",
            fontWeight: 700,
          }}
        >
          <Divider
            className="form-divider"
            orientation="left"
            orientationMargin="0"
          >
            {t("fixed_asset_additions.layout.depreciation")}
          </Divider>
        </Col>
      </Row>
      <Row gutter={[0, 8]}>
        <Col span={14}>
          {Number(merge.unbooked_amount) == 0 ||
          Number(merge.accumulated_depreciation) < 0 ? (
            depreciated
          ) : (
            <Card
              className="bg-gray-light"
              style={{ width: "70%", float: "right" }}
            >
              <span>{t("fixed_asset_additions.layout.depreciation_text")}</span>

              <Button
                type="primary"
                onClick={calculateDepreciation}
                style={{
                  float: i18next.language === "en" ? "right" : "left",
                }}
              >
                {t("fixed_asset_additions.layout.calculate")}
              </Button>
              <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="50%"
              >
                <Tabs
                  defaultActiveKey="1"
                  type="card"
                  size="middle"
                  onChange={onTabChange}
                  activeKey={activeTab}
                  items={[
                    {
                      label: t(
                        "activerecord.assets.classification.depreciation_type.posting_depreciation",
                      ),
                      key: "depreciation-entries",
                      children: activeTab === "depreciation-entries" && (
                        <CalculationDepreciationEntry mergeRegistry={merge} />
                      ),
                    },
                    {
                      label: t(
                        "activerecord.assets.classification.depreciation_type.manual_depreciation",
                      ),
                      key: "manual-depreciation",
                      children: activeTab === "manual-depreciation" && (
                        <CalculationDepreciationEntry
                          mergeRegistry={merge}
                          isEditable
                        />
                      ),
                    },
                  ]}
                />
              </Modal>
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};
