import React from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Result, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const { Paragraph, Text } = Typography;

const ServerError: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Result
      status="error"
      title={t("home.registration_complete.title")}
      subTitle={t(
        "templates.integrations.flash_messages.internal_server_error",
      )}
      extra={[
        <Button key="reload" onClick={() => navigate(0)}>
          {t("forms.buttons.try_again")}
        </Button>,
      ]}
    >
      <div className="desc">
        <Paragraph>
          <CloseCircleOutlined className="site-result-demo-error-icon" />
          Error while fetching data
        </Paragraph>
      </div>
    </Result>
  );
};

export default ServerError;
