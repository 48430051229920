import { PlusOutlined } from "@ant-design/icons";
import { Button, Flex, Space } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useAppSelector } from "hooks/permission";
import { getPermission } from "features/permissions/PermissionSlice";

const FixedAssetAdditionActionButtons = () => {
  const { t } = useTranslation();
  const permission = useAppSelector(getPermission("fixedAssetAddition"));
  const assetClassificationPermission = useAppSelector(
    getPermission("assetClassification"),
  );

  return (
    <Flex vertical gap={10} align="end">
      <Space>
        {permission.canWrite && assetClassificationPermission?.canRead && (
          <Link to="/tenant/fixed_asset_additions/new">
            <Button icon={<PlusOutlined />} type="primary">
              {t("fixed_asset_additions.layout.new")}
            </Button>
          </Link>
        )}
      </Space>
    </Flex>
  );
};

export default FixedAssetAdditionActionButtons;
