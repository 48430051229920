import React from "react";
import { AssetRegistry } from "types/AssetRegistry";
import { CalculateDeprecation } from "../CalculateDepreciation";
import { Form, Spin } from "antd";
import { uniqBy } from "lodash";

import { useQuery } from "react-query";
import { assetRegistryKeys } from "features/query-keys";
import { getAssetRegistries } from "api/AssetRegistry";

export const DepreciationDetails = ({
  mainRegistry,
}: {
  mainRegistry: AssetRegistry;
}) => {
  const form = Form.useFormInstance();
  const billWatch = Form.useWatch(
    ["fixed_asset_addition", "fixed_asset_addition_transactions_attributes", 0],
    form,
  );
  const registryWatch = Form.useWatch(["asset_registry"], form);
  const registries = React.useRef([mainRegistry]);

  const filters = {
    "q[commercial_document_id_eq]": billWatch?.transactionable_id,
    "q[commercial_document_type_eq]": "Bill",
  };
  const registryQuery = useQuery({
    queryKey: assetRegistryKeys.list(filters),
    queryFn: () => {
      if (
        !billWatch?.transactionable_id ||
        billWatch?.transactionable_type !== "Bill"
      )
        return;

      return getAssetRegistries({ filters: filters });
    },
  });

  if (registryQuery.data) {
    registries.current = uniqBy([mainRegistry, ...registryQuery.data], "id");
  } else {
    registries.current = [mainRegistry];
  }

  React.useEffect(() => {
    if (!billWatch) return;

    registryQuery.refetch();
  }, [form, billWatch, registryWatch]);

  const depreciationComponent = (
    <CalculateDeprecation registries={registries.current} />
  );
  if (registryQuery.isLoading)
    return <Spin size="small">{depreciationComponent}</Spin>;

  return depreciationComponent;
};
