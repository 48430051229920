import axios from "axios";
import {
  AllCommercialDocuments,
  Bill,
  CommercialDocument,
} from "types/CommercialDocument";

export const getAllCommercialDocuments = async ({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: {
  search?: string;
  page?: number;
  pageSize?: number;
  sorter?: string;
  filters?: any;
}): Promise<AllCommercialDocuments> => {
  const { data } = await axios.get("/tenant/cds/all.json", {
    params: {
      "q[reference_cont]": search,
      "q[s]": sorter || "created_at desc",
      page,
      per_page: pageSize || 20,
      ...filters,
    },
  });

  return data.documents;
};

export const getBill = async ({
  billID,
}: {
  billID: number | string;
}): Promise<Bill> => {
  const { data } = await axios.get(`/tenant/bills/${billID}.json`);

  return data;
};

export const getCommercialDocument = async ({
  commercialDocumentID,
}: {
  commercialDocumentID: number;
}): Promise<CommercialDocument> => {
  const { data } = await axios.get(
    `/tenant/cds/all/${commercialDocumentID}.json`,
  );

  return data;
};

export const calculateLineTotal = async (params: {
  quantity: number;
  unit_price: number;
  discount_value: number;
  discount_type: string;
  tax: number | string;
  is_inclusive: string;
  meta?: unknown;
}): Promise<{
  amount_after_discount: number;
  tax_value: number;
  tax_percentage: number;
  line_total: number;
  meta?: any;
}> => {
  const { data } = await axios.post(
    "/tenant/cd/calculate_line_total.json",
    params,
  );

  return data;
};
