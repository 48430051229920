import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/index";

export type PermissionData = {
  canRead: boolean;
  canWrite: boolean;
  canRemove: boolean;
  canApprove: boolean;
};

export type PermissionState = {
  [key: string]: PermissionData;
};

export type PermissionPayload = {
  moduleName: string;
  canRead: boolean;
  canWrite: boolean;
  canRemove: boolean;
  canApprove: boolean;
};

const initialState: PermissionState = {
  fixedAssetAddition: {
    canRead: true,
    canWrite: false,
    canRemove: false,
    canApprove: false,
  },
};

export const permissionSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    updatePermission: (state, action: PayloadAction<PermissionPayload>) => {
      state[action.payload.moduleName] = {
        canRead: action.payload.canRead,
        canWrite: action.payload.canWrite,
        canRemove: action.payload.canRemove,
        canApprove: action.payload.canApprove,
      };
    },
  },
});

export const { updatePermission } = permissionSlice.actions;
export const getPermission = (moduleName: string) => (state: RootState) => {
  if (!state.permission) {
    return initialState;
  }

  return state.permission[moduleName] || initialState;
};

export default permissionSlice.reducer;
