import axios from "axios";
import { BudgetBreakdownReqBody, GetBreakdownResponse } from "types/budget";
import { Dimensions } from "types/dimensions";
import { AssetClassifications } from "types/AssetClassification";

export async function getBudgets(page, filters, pageSize, sorter) {
  const { data } = await axios.get(`/tenant/budgets.json`, {
    params: {
      page,
      per_page: pageSize,
      "q[s]": sorter,
      ...filters,
    },
  });
  return data;
}

export async function getSingleBudget(id) {
  const { data } = await axios.get(`/tenant/budgets/${id}.json`, {});
  return data;
}
export async function getDateRanges(id, type) {
  const { data } = await axios.get(
    `/tenant/budgets/${id}/date_ranges.json?cycle=${type}`,
    {},
  );
  return data.date_ranges;
}

export async function getBudgetsLastReference() {
  const { data } = await axios.get(`/tenant/budgets/next_code.json`);
  return data;
}

export async function getAccounts() {
  const { data } = await axios.get(`/tenant/accounts.json?nested=true`);
  return data;
}
export async function getBudgetsPermissions() {
  const { data } = await axios.get(`/tenant/budgets/permissions.json`);
  return data;
}

export async function getCategories() {
  const { data } = await axios.get(`/tenant/categories.json?nested=true`);
  return data;
}
export async function getAssetClassifications({
  search = "",
  page = 1,
  pageSize = 20,
  sorter = "created_at desc",
  filters = {},
}: {
  search?: string;
  page?: number;
  pageSize?: number;
  sorter?: string;
  filters?: any;
} = {}): Promise<AssetClassifications> {
  const { data } = await axios.get(`/tenant/asset_classifications.json`, {
    params: {
      "q[arabic_name_or_english_name_cont]": search,
      "q[s]": sorter || "created_at desc",
      ...filters,
      page: page,
      per_page: pageSize,
    },
  });
  return data;
}

export async function postBudget(formData, id = null) {
  try {
    const response = await axios.post("/tenant/budgets.json", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateBudget(formData, id = null) {
  try {
    const response = await axios.patch(`/tenant/budgets/${id}.json`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function postBudgetDetails(formData, id) {
  try {
    const response = await axios.patch(`/tenant/budgets/${id}.json`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getBudgetDimensions(page): Promise<Dimensions> {
  const { data } = await axios.get(`/tenant/dimensions.json?page=${page}`);
  return data;
}

export async function postBudgetingValueBreakdown(
  requestBody: BudgetBreakdownReqBody,
) {
  const response = await axios.post(
    `/tenant/budgeting_value_breakdowns.json`,
    requestBody,
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return response.data;
}

export async function getBudgetingValueBreakdowns(
  id: number,
  valueIndex: number,
): Promise<GetBreakdownResponse> {
  const { data } = await axios.get<GetBreakdownResponse>(
    `/tenant/budgeting_value_breakdowns.json?budgeting_value_id=${id}&value_index=${valueIndex}`,
  );
  return data;
}
