import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { groupBy, snakeCase } from "lodash";

import { type CommercialDocument } from "types/CommercialDocument";
import { SelectWithSearch } from "components/form/SelectWithSearch";
import { getAllCommercialDocuments, getCommercialDocument } from "api/Bill";
import { commercialDocumentKeys } from "features/query-keys";
import { FixedAssetAdditionTransaction } from "types/FixedAssetAddition";

export const BillSelector = ({
  initialData,
}: {
  initialData?: FixedAssetAdditionTransaction;
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const bill = initialData?.transactionable as CommercialDocument;

  const initialOpts = bill
    ? [
        {
          label: (
            <span>
              {t(
                `activerecord.external_documents.${snakeCase(bill.doc_kind || bill.type)}.title`,
              )}
            </span>
          ),
          title: bill.doc_kind || bill.type,
          options: [
            {
              value: bill.id,
              label: bill.reference,
              ...bill,
            },
          ],
        },
      ]
    : [];

  const groupDocuments = async (options: any) => {
    const result = await getAllCommercialDocuments(options);
    const groupedResult = groupBy(result, "doc_kind");

    const groupedOptions = [];
    for (let docType of Object.keys(groupedResult)) {
      groupedOptions.push({
        label: (
          <span>
            {t(`activerecord.external_documents.${snakeCase(docType)}.title`)}
          </span>
        ),
        title: docType,
        id: docType,
        options: groupedResult[docType].map((doc: CommercialDocument) => ({
          value: doc.id,
          label: doc.reference,
          ...doc,
        })),
      });
    }
    return groupedOptions;
  };

  const handleBillChange = (_value: string, option: CommercialDocument) => {
    form.setFieldValue(
      [
        "fixed_asset_addition",
        "fixed_asset_addition_transactions_attributes",
        0,
        "transactionable_type",
      ],
      option.doc_kind,
    );
  };

  return (
    <div>
      <Form.Item
        initialValue={bill?.id}
        label={t("activerecord.attributes.fixed_asset_addition.bill_reference")}
        name={[
          "fixed_asset_addition",
          "fixed_asset_addition_transactions_attributes",
          0,
          "transactionable_id",
        ]}
        key="linked-bill"
        rules={[
          {
            required: true,
          },
          {
            validator: async (_, value: number) => {
              if (!value) return Promise.resolve();

              const bill = await getCommercialDocument({
                commercialDocumentID: value,
              });
              if (!(bill && bill.doc_kind === "Bill")) {
                return Promise.resolve();
              }

              if (bill.untracked_products_count === 0) {
                return Promise.reject(
                  new Error(
                    t("fixed_asset_additions.layout.form.tracked_product"),
                  ),
                );
              }

              if (bill.addition_transactions_count > 0) {
                return Promise.reject(
                  new Error(
                    t("fixed_asset_additions.layout.form.already_linked"),
                  ),
                );
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <SelectWithSearch
          initialOpts={initialOpts}
          queryKeyObject={commercialDocumentKeys}
          filters={{
            "q[type_in]": ["Bill", "SimpleBill"],
            "q[status_in]": ["Paid", "Partially Paid", "Approved"],
          }}
          queryFn={groupDocuments}
          onChange={handleBillChange}
        />
      </Form.Item>
      <Form.Item
        hidden={true}
        initialValue={bill?.doc_kind || bill?.type || ""}
        name={[
          "fixed_asset_addition",
          "fixed_asset_addition_transactions_attributes",
          0,
          "transactionable_type",
        ]}
      >
        <Input type="text" value="" />
      </Form.Item>
    </div>
  );
};
