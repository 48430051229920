import React from "react";
import { AssetRegistry } from "types/AssetRegistry";
import { Form, Skeleton } from "antd";

import { useQuery } from "react-query";
import { assetRegistryKeys } from "features/query-keys";
import { AssetRegistryShow } from "../AssetRegistryShow";
import { getAssetRegistries } from "api/AssetRegistry";
import i18next from "i18next";

export const AssetRegistryList = ({
  mainRegistry,
}: {
  mainRegistry: AssetRegistry;
}) => {
  const form = Form.useFormInstance();
  const billWatch = Form.useWatch(
    ["fixed_asset_addition", "fixed_asset_addition_transactions_attributes", 0],
    form,
  );

  const registries = React.useRef([]);

  const filters = {
    "q[commercial_document_id_eq]": billWatch?.transactionable_id,
    "q[commercial_document_type_eq]": "Bill",
  };
  const registryQuery = useQuery({
    queryKey: assetRegistryKeys.list(filters),
    queryFn: () => {
      if (
        !billWatch?.transactionable_id ||
        billWatch?.transactionable_type !== "Bill"
      )
        return;

      return getAssetRegistries({ filters: filters });
    },
  });

  if (registryQuery.data) {
    registries.current = registryQuery.data.filter(
      (registry) => registry.id !== mainRegistry.id,
    );
  } else {
    registries.current = [];
  }

  React.useEffect(() => {
    if (!(billWatch && billWatch.transactionable_id)) return;

    registryQuery.refetch();
  }, [form, billWatch]);

  if (registryQuery.isLoading) return <Skeleton />;

  return (
    <div style={{ maxHeight: 400, overflowY: "scroll" }}>
      {registries.current.map((registry) => (
        <AssetRegistryShow
          assetRegistry={registry}
          title={`${
            i18next.language === "en"
              ? registry.fixed_asset?.en_name
              : registry.fixed_asset?.name
          } - ${registry.sku}`}
          key={`${registry.id}-${registry.sku}`}
        />
      ))}
    </div>
  );
};
