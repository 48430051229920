import { Form, FormInstance } from "antd";
import { getAssetRegistry } from "api/AssetRegistry";
import { assetRegistryKeys } from "features/query-keys";
import { useContext, useEffect, useState } from "react";
import { UseMutationResult, UseQueryResult, useQuery } from "react-query";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import dayjs, { Dayjs } from "dayjs";
import DisabledContext from "antd/lib/config-provider/DisabledContext";
import { handleAssetChange, handleClassificationChange } from "../helpers";
import RecordContext from "contexts/RecordContext";
import { AssetRegistry } from "types/AssetRegistry";

export type UseAddition = {
  assetClassification: {
    assetClassificationID: number;
    setAssetClassificationID: (value: number) => void;
    handleChange: (value: string) => void;
  };
  fixedAsset: {
    assetID: number;
    setAssetID: (value: number) => void;
    handleChange: (value: string) => void;
    isDisabled: boolean;
  };
  lifeCycle: {
    lifeCycle: string;
    setLifeCycle: (value: string) => void;
    handleChange: (event: any) => void;
  };
  mainAssetRegistry: {
    mainAssetRegistry: number;
    setMainAssetRegistry: (value: number) => void;
    query: UseQueryResult<AssetRegistry>;
    handleChange: (value: string, options: any) => void;
    isDisabled: boolean;
  };
  mergeRegistry: {
    handleChange: (value: string, options: any) => void;
  };
  handleCreateAddition: (
    form: HTMLFormElement,
    mutation: UseMutationResult<FixedAssetAddition>,
  ) => void;
  form: FormInstance;
  isDisabled: boolean;
  additionDateWatch: Dayjs;
};

const initLifeCycle = (record: FixedAssetAddition): string => {
  if (
    record?.life_cycle_type === "new_date_of_life_cycle" ||
    !record?.life_cycle_type
  ) {
    return "new_date_of_life_cycle";
  }

  return "add_to_life_cycle";
};

export const useAddition = () => {
  const record = useContext(RecordContext) as FixedAssetAddition;
  const [formState, setFormState] = useState({
    assetClassificationID: record?.asset_classification?.id || null,
    assetID: record?.fixed_asset?.id || null,
    lifeCycle: initLifeCycle(record),
    mainRegistry: record?.asset_registry_id || null,
  });
  const isAssetDisabled = !formState.assetClassificationID;
  const isRegistryDisabled = isAssetDisabled || !formState.assetID;

  const [newForm] = Form.useForm();
  const form = Form.useFormInstance() || newForm;
  const additionDate = Form.useWatch(["fixed_asset_addition", "date"], form);
  const registryData = Form.useWatch(["asset_registry"], form);
  const componentDisabled = useContext(DisabledContext);

  const main = useQuery({
    queryKey: assetRegistryKeys.details(formState.mainRegistry, additionDate),
    queryFn: () => {
      if (!formState.mainRegistry) return;

      const dateString =
        additionDate && dayjs(additionDate).format("YYYY-MM-DD");
      return getAssetRegistry(formState.mainRegistry, {
        depreciation_date: dateString,
      });
    },
  });

  useEffect(() => {
    if (!formState.mainRegistry) {
      return;
    }

    if (
      !componentDisabled &&
      form.getFieldValue("lifecycle") === "new_date_of_life_cycle"
    )
      form.setFieldValue(["fixed_asset_addition", "life_cycle"], "");

    main.refetch();
  }, [formState.mainRegistry, registryData]);

  const handleChangeAssetClassification = (value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      assetClassificationID: Number(value),
      assetID: null,
      mainRegistry: null,
    }));
    handleClassificationChange(form);
    form.setFieldValue(["fixed_asset_addition", "date"], "");
  };

  const handleChangeAsset = (value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      assetID: Number(value),
      mainRegistry: null,
    }));
    handleAssetChange(form);
    form.setFieldValue(["fixed_asset_addition", "date"], "");
  };

  const showAssetRegistry =
    (registryType: string) => (value: string, _option?: any) => {
      const numberValue = Number(value);
      if (registryType === "main") {
        form.setFieldValue(["fixed_asset_addition", "date"], "");
        setFormState((prevState) => ({
          ...prevState,
          mainRegistry: numberValue,
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          mergeRegistry: numberValue,
        }));
      }
    };

  const handleLifeCycleChange = (event: any) => {
    form.setFieldValue(["fixed_asset_addition", "life_cycle"], "");
    form.setFieldValue(
      ["fixed_asset_addition", "life_cycle_type"],
      event.target.value,
    );
    setFormState((prevState) => ({
      ...prevState,
      lifeCycle: event.target.value,
    }));
  };

  const handleCreateAddition = (
    formValues: HTMLFormElement,
    mutation: UseMutationResult<FixedAssetAddition>,
  ) => {
    const lifeCycleData =
      form.getFieldValue("lifecycle") === "new_date_of_life_cycle"
        ? formValues.fixed_asset_addition.life_cycle &&
          formValues.fixed_asset_addition.life_cycle.format("YYYY-MM-DD")
        : formValues.fixed_asset_addition.life_cycle;
    let billAttributes = {};

    if (formValues.bill) {
      billAttributes = {
        ...formValues.bill,
        issue_date:
          formValues.bill?.issue_date &&
          formValues.bill.issue_date.format("YYYY-MM-DD"),
        due_date:
          formValues.bill?.due_date &&
          formValues.bill.due_date.format("YYYY-MM-DD"),
        supply_date:
          formValues.bill?.supply_date &&
          formValues.bill.supply_date.format("YYYY-MM-DD"),
      };
    }

    const values = {
      fixed_asset_addition: {
        ...formValues.fixed_asset_addition,
        date: formValues.fixed_asset_addition.date.format("YYYY-MM-DD"),
        life_cycle: lifeCycleData,
      },
      depreciation: formValues.depreciation,
      bill: billAttributes,
    };

    mutation.mutate(values);
  };

  const additionState: UseAddition = {
    assetClassification: {
      assetClassificationID: formState.assetClassificationID,
      setAssetClassificationID: (value: number) => {
        setFormState((prevState) => ({
          ...prevState,
          assetClassificationID: value,
        }));
      },
      handleChange: handleChangeAssetClassification,
    },
    fixedAsset: {
      assetID: formState.assetID,
      setAssetID: (value: number) => {
        setFormState((prevState) => ({
          ...prevState,
          assetID: value,
        }));
      },
      handleChange: handleChangeAsset,
      isDisabled: isAssetDisabled,
    },
    lifeCycle: {
      lifeCycle: formState.lifeCycle,
      setLifeCycle: (value: string) => {
        setFormState((prevState) => ({
          ...prevState,
          lifeCycle: value,
        }));
      },
      handleChange: handleLifeCycleChange,
    },
    mainAssetRegistry: {
      mainAssetRegistry: formState.mainRegistry,
      setMainAssetRegistry: (value: number) => {
        setFormState((prevState) => ({
          ...prevState,
          mainRegistry: value,
        }));
      },
      query: main,
      handleChange: showAssetRegistry("main"),
      isDisabled: isRegistryDisabled,
    },
    mergeRegistry: {
      handleChange: showAssetRegistry("merge"),
    },
    handleCreateAddition,
    form,
    isDisabled: componentDisabled,
    additionDateWatch: additionDate,
  };

  return additionState;
};
