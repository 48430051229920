import { Card, Col, Divider, Image, Row, Typography } from "antd";
import React from "react";

import { fallbackImage } from "assets/fallbackImage";
import { useTranslation } from "react-i18next";
import { AssetRegistry } from "types/AssetRegistry";

export const AssetRegistryShow = ({
  assetRegistry,
  title,
}: {
  assetRegistry: AssetRegistry;
  title: string;
}) => {
  const { t } = useTranslation();

  return (
    <Row style={{ marginLeft: 20, marginBottom: 20 }}>
      <Typography.Title level={4} style={{ color: "#677292", fontWeight: 500 }}>
        {title}
      </Typography.Title>
      <Divider />
      <Col span={24}>
        <Card className="show-card">
          <Row>
            <Col span={8}>
              <Card.Meta
                avatar={
                  <Image
                    width={200}
                    src={assetRegistry.fixed_asset.image_url}
                    fallback={fallbackImage}
                  />
                }
              />
            </Col>
            <Col span={8}>
              <div style={{ marginLeft: 10 }}>
                <Card.Meta
                  title={t(
                    "activerecord.attributes.fixed_asset.asset_registry_other_info.serial_number",
                  )}
                  description={assetRegistry.serial_number || "N/A"}
                />
                <Card.Meta
                  title={t("activerecord.attributes.fixed_asset.received_date")}
                  description={assetRegistry.start_depreciation_date || "N/A"}
                />
                <Card.Meta
                  title={t("activerecord.attributes.fixed_asset.book_value")}
                  description={assetRegistry.book_value || "N/A"}
                />
              </div>
            </Col>
            <Col span={8}>
              <Card.Meta
                title={t(
                  "fixed_asset_additions.layout.accumulated_depreciation",
                )}
                description={assetRegistry.accumulated_depreciation}
              />
              <Card.Meta
                title={t("fixed_asset_additions.layout.unbooked_depreciation")}
                description={assetRegistry.unbooked_amount || "0.00"}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
