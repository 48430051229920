import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";

import { useAppDispatch } from "hooks/permission";
import {
  updatePermission,
  type PermissionState,
} from "features/permissions/PermissionSlice";
import {
  FixedAssetAdditionsTable,
  FixedAssetAdditionNew,
} from "components/tenant/FixedAssetAdditions";
import NoMatch from "components/layout/NoMatch";
import { FixedAssetAdditionEdit } from "components/tenant/FixedAssetAdditions/FixedAssetAdditionEdit";
import { useQuery } from "react-query";
import { currentUserKeys } from "features/query-keys";
import { getCurrentUser } from "api/CurrentUser";
import { Skeleton } from "antd";
import UserContext from "contexts/UserContext";

const FixedAssetAdditions = ({
  permissions,
}: {
  permissions: PermissionState;
}) => {
  const dispatch = useAppDispatch();
  Object.keys(permissions).forEach((moduleName) => {
    dispatch(
      updatePermission({
        moduleName: moduleName,
        ...permissions[moduleName],
      }),
    );
  });
  const { data, isLoading } = useQuery({
    queryKey: currentUserKeys.all,
    queryFn: getCurrentUser,
  });

  if (isLoading) return <Skeleton />;

  return (
    <UserContext.Provider value={data}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/tenant/fixed_asset_additions"
            element={<FixedAssetAdditionsTable />}
          />
          {permissions.assetClassification?.canRead && (
            <>
              <Route
                path="/tenant/fixed_asset_additions/new"
                element={<FixedAssetAdditionNew />}
              />
              <Route
                path="/tenant/fixed_asset_additions/:additionID/edit"
                element={<FixedAssetAdditionEdit />}
              />
              <Route
                path="/tenant/fixed_asset_additions/:additionID"
                element={<FixedAssetAdditionEdit disabled />}
              />
              <Route
                path="/tenant/fixed_asset_additions/new/merge"
                element={<FixedAssetAdditionNew.Merge />}
              />

              {permissions.bill?.canRead && (
                <Route
                  path="/tenant/fixed_asset_additions/new/link_bill"
                  element={<FixedAssetAdditionNew.LinkBill />}
                />
              )}
              {permissions.bill?.canApprove && (
                <Route
                  path="/tenant/fixed_asset_additions/new/create_bill"
                  element={<FixedAssetAdditionNew.CreateBill />}
                />
              )}
            </>
          )}

          <Route path="/tenant/fixed_asset_additions/*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default FixedAssetAdditions;
