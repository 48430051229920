import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Tooltip,
  Typography,
} from "antd";
import i18next from "i18next";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { AssetRegistry } from "types/AssetRegistry";

export const CalculationDepreciationEntry = ({
  mergeRegistry,
  isEditable = false,
}: {
  mergeRegistry: AssetRegistry;
  isEditable?: boolean;
}) => {
  const { t } = useTranslation();

  const registryRow = (registry: AssetRegistry): ReactNode => {
    return (
      <>
        <Divider orientation="left" orientationMargin="0" key="ref-divider">
          {t("activerecord.attributes.fixed_asset.registered_asset_ref")}
        </Divider>
        <Card.Meta
          key="ref-meta"
          description={
            <Tooltip
              placement="top"
              title={t(
                "activerecord.attributes.fixed_asset.registered_asset_ref",
              )}
              arrow
            >
              <Typography.Text type="secondary">{registry.sku}</Typography.Text>
            </Tooltip>
          }
        />
        <Divider orientation="left" orientationMargin={0} key="desc-divider">
          {t("activerecord.attributes.depreciation.desc")}
        </Divider>
        <Card.Meta
          key="desc-meta"
          description={
            isEditable ? (
              <Form.Item
                initialValue={registry.description}
                name="depreciation_description"
              >
                <Input type="text" />
              </Form.Item>
            ) : (
              <Tooltip
                placement="top"
                title={t("activerecord.attributes.depreciation.desc")}
                arrow
              >
                <Typography.Text type="secondary">
                  {registry.description}
                </Typography.Text>
              </Tooltip>
            )
          }
        />
        <Divider orientation="left" orientationMargin={0} key="amount-divider">
          {t("activerecord.attributes.depreciation.amount")}
        </Divider>
        <Card.Meta
          key="amount-meta"
          description={
            isEditable ? (
              <Form.Item
                initialValue={registry.unbooked_amount}
                name="depreciation_amount"
              >
                <Input type="text" />
              </Form.Item>
            ) : (
              <Tooltip
                placement="top"
                title={t("activerecord.attributes.depreciation.amount")}
                arrow
              >
                <Typography.Text type="secondary">
                  {registry.unbooked_amount}
                </Typography.Text>
              </Tooltip>
            )
          }
        />
      </>
    );
  };

  return (
    <Row>
      <Col span={24} key="merge-registry-col">
        <Card
          title={
            i18next.language === "en"
              ? mergeRegistry.fixed_asset?.en_name
              : mergeRegistry.fixed_asset?.name
          }
        >
          {registryRow(mergeRegistry)}
        </Card>
      </Col>
    </Row>
  );
};
