import React, { useEffect, useRef, useState } from "react";
import { Select } from "antd";
import { useQuery } from "react-query";
import { uniqBy, compact } from "lodash";

export const SelectWithSearch = ({
  queryFn,
  dataLabelFn,
  queryKey,
  initialOpts = [],
  disabled = false,
  filters = {},
  onChange = (_value: string | string[], _option?: any) => {},
}) => {
  const [search, setSearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [dropDownData, setDropDownData] = useState(compact(initialOpts || []));
  const [selectedValueItem, setSelectedValueItem] = useState(
    dropDownData[0]?.id,
  );

  const filterRef = useRef(filters);

  const fetchDropDownData = async (search: string, page: number) => {
    if (disabled) {
      return [];
    }

    try {
      const res = await queryFn({
        search,
        page,
        filters,
      });

      if (filterRef.current === filters) {
        setDropDownData(uniqBy([...dropDownData, ...res], "id"));
      } else {
        setSelectedValueItem(null);
        setDropDownData(res);
        filterRef.current = filters;
      }
      return res;
    } catch (_error) {
      console.log(_error);
      return [];
    }
  };

  const { data, refetch } = useQuery({
    queryKey: [queryKey, pageNumber, filters],
    queryFn: () => fetchDropDownData(search, pageNumber),
    enabled: !disabled,
  });

  useEffect(() => {
    refetch();
  }, [search, pageNumber]);

  const handleSearch = (keyword: string) => {
    setDropDownData([]);
    setSearch(keyword);
    setPageNumber(1);
  };

  const handleScroll = (e) => {
    e.persist();
    const target = e.target;
    if (
      target.scrollTop + target.offsetHeight === target.scrollHeight &&
      data.length >= 20
    ) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handleChange = (value: string, option: any) => {
    setSelectedValueItem(value);
    onChange && onChange(value, option);
  };

  const handleFocus = () => {
    setSearch("");
  };

  const selectOptions = dropDownData.map((dt) => ({
    value: dt.id,
    label: dataLabelFn(dt),
  }));

  return (
    <Select
      options={selectOptions}
      value={disabled ? null : selectedValueItem}
      defaultActiveFirstOption={true}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      showSearch={true}
      loading={true}
      disabled={disabled}
      notFoundContent={<span>Not Found</span>}
      onChange={handleChange}
      onFocus={handleFocus}
      onPopupScroll={handleScroll}
    />
  );
};
