import React from "react";
import { Form, Input } from "antd";

import "../styles.css";
import { AssetRegistry } from "types/AssetRegistry";
import { UseMutationResult } from "react-query";
import { FixedAssetAddition } from "types/FixedAssetAddition";
import {
  assetClassificationKeys,
  assetRegistryKeys,
  fixedAssetKeys,
} from "features/query-keys";
import RecordContext from "contexts/RecordContext";
import GeneralForm from "../GeneralForm";
import { CalculateDeprecation } from "../CalculateDepreciation";
import BillForm from "./BillForm";

export const FixedAssetAdditionCreateBillForm = ({
  mutation,
}: {
  mutation: UseMutationResult<FixedAssetAddition>;
}) => {
  const record = React.useContext(RecordContext) as FixedAssetAddition;
  const transaction =
    record?.fixed_asset_addition_transactions &&
    record.fixed_asset_addition_transactions.find(
      (tr) => tr.transactionable_type === "Bill",
    );

  const calculateDepreciationComponent = (mainRegistry: AssetRegistry) => {
    return <CalculateDeprecation registries={[mainRegistry]} />;
  };

  return (
    <GeneralForm
      record={record}
      mutation={mutation}
      renderCalculateDepreciationComponent={calculateDepreciationComponent}
      additionType="create_bill"
    >
      <Form.Item
        hidden
        name={["fixed_asset_addition", "addition_type"]}
        initialValue="create_bill"
      >
        <Input type="hidden" />
      </Form.Item>
      <BillForm
        record={transaction?.transactionable || transaction?.attributes?.bill}
      />
    </GeneralForm>
  );
};
